import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";
import { SlEnvelopeOpen, SlLocationPin } from "react-icons/sl";
// import { BsSearch } from "react-icons/bs";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Logo } from "./../images";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <Container>
      <div className="d-flex align-items-center">
          <div className="site-branding flex-grow-1">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="header-info">
            <div className="header-box">
              <a href="tel:O1204424713">
                <span className="header-icon">
                  <FiPhoneCall />
                </span>
                <span className="header-box-title">Please Make a call</span>
                <span className="header-box-content">O1204424713</span>
              </a>
            </div>
            <div className="header-box">
              <a href="mailto:info@motivusi.com">
                <span className="header-icon">
                  <SlEnvelopeOpen />
                </span>
                <span className="header-box-title">E-mail Address</span>
                <span className="header-box-content">
                  info@motivusi.com
                </span>
              </a>
            </div>
            <div className="header-box">
              <Link target="_blank" to="https://maps.app.goo.gl/VVKNk33WRzRZx8hYA">
                <span className="header-icon">
                  <SlLocationPin />
                </span>
                <span className="header-box-title">Our Office Address</span>
                <span className="header-box-content">
                  G-122, Sector 63, Noida 201301
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <div className="site-header-menu">
        <Container>
          <Row>
            <Col md="12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="navigation">
                  <Navbar expand="lg">
                    <Container>
                      <Navbar.Brand>
                        <img
                          src={Logo}
                          alt="logo"
                          onClick={() => navigate("/")}
                        />
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link onClick={() => navigate("/")}>
                            Home
                          </Nav.Link>
                          <Nav.Link onClick={() => navigate("/about-us")}>
                            About Us
                          </Nav.Link>
                          <Nav.Link onClick={() => navigate("/products")}>
                            OUR COMPANIES
                          </Nav.Link>
                          <Nav.Link onClick={() => navigate("/gallery")}>
                            Gallery
                          </Nav.Link>
                          <Nav.Link onClick={() => navigate("/careers")}>
                            Careers
                          </Nav.Link>
                          <Nav.Link onClick={() => navigate("/contact-us")}>
                            Contact
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                  <div className="right-box ml-auto">{/* <BsSearch /> */}</div>
                </div>
                <div className="menu-right-box">
                  <div className="header-button">
                    <Link to="/contact-us">
                      Get a quote <HiOutlineArrowNarrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Header;
