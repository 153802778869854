import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { SlEnvelopeOpen } from "react-icons/sl";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { EmailValidate, VisibleError } from "../Components";
import apiClient from "../Config/apiConfig";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [loader, setLoader] = useState(false);
  const [contactData, setContactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [error, setError] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneNumberError: false,
    messageError: false,
  });
  const { firstName, lastName, email, phoneNumber, message } = contactData;
  const {
    firstNameError,
    lastNameError,
    emailError,
    phoneNumberError,
    messageError,
  } = error;

  const handleChange = (e) => {
    setContactData((data) => {
      return {
        ...data,
        [e.target.name]: e.target.value,
      };
    });
  };
  const contactFromSubmit = async (e) => {
    e.preventDefault();
    let check = false;
    if (firstName.trim() === "") {
      setError((old) => {
        return {
          ...old,
          firstNameError: true,
        };
      });
      check = true;
    }
    if (lastName.trim() === "") {
      setError((old) => {
        return {
          ...old,
          lastNameError: true,
        };
      });
      check = true;
    }
    if (email.trim() === "" || !EmailValidate(email)) {
      setError((old) => {
        return {
          ...old,
          emailError: true,
        };
      });
      check = true;
    }
    if (phoneNumber.trim() === "") {
      setError((old) => {
        return {
          ...old,
          phoneNumberError: true,
        };
      });
      check = true;
    }
    if (message.trim() === "") {
      setError((old) => {
        return {
          ...old,
          messageError: true,
        };
      });
      check = true;
    }
    if (check) {
      return;
    }
    try {
      setLoader(true);
      const allData = { firstName, lastName, email, phoneNumber, message };
      const res = await apiClient.post(`api/createContact`, {
        allData,
      });

      if (res.data.status === "200") {
        toast.success(res.data.success);
        setContactData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } else if (res.data.status === "405") {
        toast.warning(res.data.error);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Contact Us</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>Contact</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="service-section contact-section">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-subtitle">GET IN TOUCH</h4>
              <h2 className="pbmit-title">We are here to assist you.</h2>
              <div className="pbmit-heading-desc">
                Don't hesitate to reach out by filling out the form below. Our team welcomes your feedback, thoughts, and questions and we look forward to helping you in any way we can!
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <FaMapMarkedAlt />
                </div>
                <div className="contact-box-content">
                  <h2>Our Location​​</h2>
                  <Link target="_blank" to='https://maps.app.goo.gl/VVKNk33WRzRZx8hYA'>G-122, Sector 63, Noida 201301.</Link>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <FiPhoneCall />
                </div>
                <div className="contact-box-content">
                  <h2>Phone Number</h2>
                  <p>
                  <Link to={'tel:0120-3101497'}>0120-3101497. (24/7)</Link>
                    <br />
                    <Link to={'tel:+917004645883'}>+91 7004645883. (24/7)</Link>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <SlEnvelopeOpen />
                </div>
                <div className="contact-box-content">
                  <h2>Email Address​</h2>
                  <Link to={'mailto:info@motivusi.com'}>
                    info@motivusi.com
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact-sec-bg">
        <Container fluid>
          <Row>
            <Col lg="6" md="12" className="bg-contact-left">
              <div className="map-sec">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.4335003093333!2d77.391297!3d28.616766999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef004b0b98bb%3A0x251dcb85df8c0ce8!2sMotivus%20Innovation%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1735993292004!5m2!1sen!2sin"   
                  width="100%"
                  title="Core System Technologies Pvt. Ltd"
                  height="470"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
            <Col lg="6" md="12" className="bg-contact-right">
              <div className="contact-form">
                <Row className="mb-3 g-3">
                  <Col lg="6" md="6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            firstNameError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={firstNameError} name="First Name" />
                  </Col>
                  <Col lg="6" md="6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            lastNameError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={lastNameError} name="Last Name" />
                  </Col>
                  <Col lg="6" md="6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            emailError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={emailError} name="Valid Email" />
                  </Col>
                  <Col lg="6" md="6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            phoneNumberError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={phoneNumberError} name="Phone No" />
                  </Col>
                  <Col lg="12" md="12">
                    <textarea
                      type="text"
                      rows={4}
                      className="form-control"
                      placeholder="Message Write Here"
                      name="message"
                      value={message}
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            messageError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={messageError} name="Message" />
                  </Col>
                  <Col sm="12">
                    <div className="header-button mt-1">
                      <Link disabled={loader} onClick={contactFromSubmit}>
                        {loader ? (
                          <div className="d-flex justify-content-center">
                            <TailSpin height="25" width="25" color="#fff" />
                          </div>
                        ) : (
                          <>
                            send message <HiOutlineArrowNarrowRight />
                          </>
                        )}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
